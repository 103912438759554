import request from '@/utils/request'
//订单一览查询
export function listPage(query) {
    return request({
      url: '/orderGinseng/listPage',
      method: 'get',
      params:query
    })
  }
//客户企业(客户管理)订单
export function orderCompanyListPage(query) {
    return request({
      url: '/companyGinseng/orderCompanyListPage',
      method: 'get',
      params:query
    })
  }
//订单详情
export function listById(query) {
    return request({
      url: '/orderGinseng/listById',
      method: 'get',
      params:query
    })
  }
//查询订单进度
export function orderScheduleListPage(query) {
    return request({
      url: '/orderGinseng/orderScheduleListPage',
      method: 'get',
      params:query
    })
  }
